
  import {shuffleArray} from '@/helper/ArrayHelper';

  export default {
    name: 'RelatedArticles',
    filters: {
      truncate(string, value) {
        return string.length > value
          ? string.substring(0, value) + '…'
          : string;
      },
    },
    props: {
      article: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        selectedItem: {},
        relatedArticles: [],
        organizationScope: process.env.SCOPE_ORGANIZATION,
      };
    },
    async fetch() {
      const related_article_ids = [];

      // Related articles based on article-n:m-relation
      this.article.related_articles.forEach((element) => {
        if (
          !related_article_ids.includes(element.id) &&
          element.id !== this.article.id
        ) {
          related_article_ids.push(element.id);
        }
      });

      // Query related Articles based on Relation-Tags-String from Strapi; first prepare string to array for query
      if (this.article.relation_tags) {
        const relation_tags_structured = this.article.relation_tags
          .split(',')
          .map((x) => ['relation_tags_contains', x.trim()]);
        relation_tags_structured.push(['scope_contains', this.article.scope]);

        const relatedArticlesByRelationTag = await this.$strapi.find(
          'articles',
          relation_tags_structured
        );

        relatedArticlesByRelationTag.forEach((element) => {
          if (
            !related_article_ids.includes(element.id) &&
            element.id !== this.article.id
          ) {
            related_article_ids.push(element.id);
          }
        });
      }

      // get TagIds for given article
      const tagIds = [];
      this.article.article_tags.forEach((element) => {
        tagIds.push(['article_tags.id', element.id]);
      });

      // Query related Articles based on Tags from Strapi --> Geht nicht mit Array! Liegt das vielleicht an unserer Strapi-Version???
      // this.relatedArticlesByTag = await this.$strapi.find('articles', {
      //   'article_tags.id': tagIds,
      // });
      // Ergibt Query wie: //localhost:1337/articles?article_tags.id=1%2C2 und diese funktioniert nicht!!!
      if (tagIds.length > 0) {
        tagIds.push(['scope_contains', this.article.scope]);
        const relatedArticlesByTag = await this.$strapi.find(
          'articles',
          tagIds
        );

        relatedArticlesByTag.forEach((element) => {
          if (
            !related_article_ids.includes(element.id) &&
            element.id !== this.article.id
          ) {
            related_article_ids.push(element.id);
          }
        });
      }

      // Query article data for related articles to get full category data for the link
      if (related_article_ids.length > 0) {
        const related_articles_search_params = [
          ['scope_contains', this.article.scope],
          ['article_category_null', false],
        ];
        related_article_ids.forEach((element) => {
          related_articles_search_params.push(['id_in', element]);
        });

        // To have always the category of the articles in it
        this.relatedArticles = await this.$strapi.find(
          'articles',
          related_articles_search_params
        );
        this.relatedArticles = shuffleArray(this.relatedArticles).slice(0, 5);
      }
    },
  };
